"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.texOldstyleBold = void 0;
exports.texOldstyleBold = {
    0x30: [.46, .017, .575],
    0x31: [.461, 0, .575],
    0x32: [.46, 0, .575],
    0x33: [.461, .211, .575],
    0x34: [.469, .194, .575],
    0x35: [.461, .211, .575],
    0x36: [.66, .017, .575],
    0x37: [.476, .211, .575],
    0x38: [.661, .017, .575],
    0x39: [.461, .21, .575],
    0x41: [.751, .049, .921, { ic: .068, sk: .224 }],
    0x42: [.705, .017, .748, { sk: .16 }],
    0x43: [.703, .02, .613, { sk: .16 }],
    0x44: [.686, 0, .892, { sk: .0958 }],
    0x45: [.703, .016, .607, { ic: .02, sk: .128 }],
    0x46: [.686, .03, .814, { ic: .116, sk: .128 }],
    0x47: [.703, .113, .682, { sk: .128 }],
    0x48: [.686, .048, .987, { sk: .128 }],
    0x49: [.686, 0, .642, { ic: .104, sk: .0319 }],
    0x4A: [.686, .114, .779, { ic: .158, sk: .192 }],
    0x4B: [.703, .017, .871, { sk: .0639 }],
    0x4C: [.703, .017, .788, { sk: .16 }],
    0x4D: [.703, .049, 1.378, { sk: .16 }],
    0x4E: [.84, .049, .937, { ic: .168, sk: .0958 }],
    0x4F: [.703, .017, .906, { sk: .128 }],
    0x50: [.686, .067, .81, { ic: .036, sk: .0958 }],
    0x51: [.703, .146, .939, { sk: .128 }],
    0x52: [.686, .017, .99, { sk: .0958 }],
    0x53: [.703, .016, .696, { ic: .025, sk: .16 }],
    0x54: [.72, .069, .644, { ic: .303, sk: .0319 }],
    0x55: [.686, .024, .715, { ic: .056, sk: .0958 }],
    0x56: [.686, .077, .737, { ic: .037, sk: .0319 }],
    0x57: [.686, .077, 1.169, { ic: .037, sk: .0958 }],
    0x58: [.686, 0, .817, { ic: .089, sk: .16 }],
    0x59: [.686, .164, .759, { ic: .038, sk: .0958 }],
    0x5A: [.686, 0, .818, { ic: .035, sk: .16 }],
};
