"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.texSize3 = void 0;
exports.texSize3 = {
    0x28: [1.45, .949, .736],
    0x29: [1.45, .949, .736],
    0x2F: [1.45, .949, 1.044],
    0x5B: [1.45, .949, .528],
    0x5C: [1.45, .949, 1.044],
    0x5D: [1.45, .949, .528],
    0x7B: [1.45, .949, .75],
    0x7D: [1.45, .949, .75],
    0x2C6: [.772, -0.564, 1.444],
    0x2DC: [.749, -0.61, 1.444],
    0x302: [.772, -0.564, 0],
    0x303: [.749, -0.61, 0],
    0x2044: [1.45, .949, 1.044],
    0x221A: [1.45, .95, 1, { ic: .02 }],
    0x2308: [1.45, .949, .583],
    0x2309: [1.45, .949, .583],
    0x230A: [1.45, .949, .583],
    0x230B: [1.45, .949, .583],
    0x2329: [1.45, .95, .75],
    0x232A: [1.45, .949, .75],
    0x27E8: [1.45, .95, .75],
    0x27E9: [1.45, .949, .75],
    0x3008: [1.45, .95, .75],
    0x3009: [1.45, .949, .75],
};
