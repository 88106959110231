"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.monospace = void 0;
exports.monospace = {
    0x20: [0, 0, .525],
    0x21: [.622, 0, .525],
    0x22: [.623, -0.333, .525],
    0x23: [.611, 0, .525],
    0x24: [.694, .082, .525],
    0x25: [.694, .083, .525],
    0x26: [.622, .011, .525],
    0x27: [.611, -0.287, .525],
    0x28: [.694, .082, .525],
    0x29: [.694, .082, .525],
    0x2A: [.52, -0.09, .525],
    0x2B: [.531, -0.081, .525],
    0x2C: [.14, .139, .525],
    0x2D: [.341, -0.271, .525],
    0x2E: [.14, 0, .525],
    0x2F: [.694, .083, .525],
    0x3A: [.431, 0, .525],
    0x3B: [.431, .139, .525],
    0x3C: [.557, -0.055, .525],
    0x3D: [.417, -0.195, .525],
    0x3E: [.557, -0.055, .525],
    0x3F: [.617, 0, .525],
    0x40: [.617, .006, .525],
    0x5B: [.694, .082, .525],
    0x5C: [.694, .083, .525],
    0x5D: [.694, .082, .525],
    0x5E: [.611, -0.46, .525],
    0x5F: [-0.025, .095, .525],
    0x60: [.681, -0.357, .525],
    0x7B: [.694, .083, .525],
    0x7C: [.694, .082, .525],
    0x7D: [.694, .083, .525],
    0x7E: [.611, -0.466, .525],
    0x7F: [.612, -0.519, .525],
    0xA0: [0, 0, .525],
    0x131: [.431, 0, .525],
    0x237: [.431, .228, .525],
    0x2B9: [.623, -0.334, .525],
    0x300: [.611, -0.485, 0],
    0x301: [.611, -0.485, 0],
    0x302: [.611, -0.46, 0],
    0x303: [.611, -0.466, 0],
    0x304: [.577, -0.5, 0],
    0x306: [.611, -0.504, 0],
    0x308: [.612, -0.519, 0],
    0x30A: [.619, -0.499, 0],
    0x30C: [.577, -0.449, 0],
    0x391: [.623, 0, .525],
    0x392: [.611, 0, .525],
    0x393: [.611, 0, .525],
    0x394: [.623, 0, .525],
    0x395: [.611, 0, .525],
    0x396: [.611, 0, .525],
    0x397: [.611, 0, .525],
    0x398: [.621, .01, .525],
    0x399: [.611, 0, .525],
    0x39A: [.611, 0, .525],
    0x39B: [.623, 0, .525],
    0x39C: [.611, 0, .525],
    0x39D: [.611, 0, .525],
    0x39E: [.611, 0, .525],
    0x39F: [.621, .01, .525],
    0x3A0: [.611, 0, .525],
    0x3A1: [.611, 0, .525],
    0x3A3: [.611, 0, .525],
    0x3A4: [.611, 0, .525],
    0x3A5: [.622, 0, .525],
    0x3A6: [.611, 0, .525],
    0x3A7: [.611, 0, .525],
    0x3A8: [.611, 0, .525],
    0x3A9: [.622, 0, .525],
    0x2017: [-0.025, .095, .525],
    0x2032: [.623, -0.334, .525],
    0x2033: [.623, 0, 1.05],
    0x2034: [.623, 0, 1.575],
    0x2044: [.694, .083, .525],
    0x2057: [.623, 0, 2.1],
    0x2206: [.623, 0, .525],
};
