"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.texOldstyle = void 0;
exports.texOldstyle = {
    0x30: [.452, .022, .5],
    0x31: [.453, 0, .5],
    0x32: [.453, 0, .5],
    0x33: [.452, .216, .5],
    0x34: [.464, .194, .5],
    0x35: [.453, .216, .5],
    0x36: [.665, .022, .5],
    0x37: [.463, .216, .5],
    0x38: [.666, .021, .5],
    0x39: [.453, .216, .5],
    0x41: [.728, .05, .798, { ic: .021, sk: .194 }],
    0x42: [.705, .022, .657, { sk: .139 }],
    0x43: [.705, .025, .527, { sk: .139 }],
    0x44: [.683, 0, .771, { sk: .0833 }],
    0x45: [.705, .022, .528, { ic: .036, sk: .111 }],
    0x46: [.683, .032, .719, { ic: .11, sk: .111 }],
    0x47: [.704, .119, .595, { sk: .111 }],
    0x48: [.683, .048, .845, { sk: .111 }],
    0x49: [.683, 0, .545, { ic: .097, sk: .0278 }],
    0x4A: [.683, .119, .678, { ic: .161, sk: .167 }],
    0x4B: [.705, .022, .762, { sk: .0556 }],
    0x4C: [.705, .022, .69, { sk: .139 }],
    0x4D: [.705, .05, 1.201, { sk: .139 }],
    0x4E: [.789, .05, .82, { ic: .159, sk: .0833 }],
    0x4F: [.705, .022, .796, { sk: .111 }],
    0x50: [.683, .057, .696, { ic: .037, sk: .0833 }],
    0x51: [.705, .131, .817, { sk: .111 }],
    0x52: [.682, .022, .848, { sk: .0833 }],
    0x53: [.705, .022, .606, { ic: .036, sk: .139 }],
    0x54: [.717, .068, .545, { ic: .288, sk: .0278 }],
    0x55: [.683, .028, .626, { ic: .061, sk: .0833 }],
    0x56: [.683, .052, .613, { ic: .045, sk: .0278 }],
    0x57: [.683, .053, .988, { ic: .046, sk: .0833 }],
    0x58: [.683, 0, .713, { ic: .094, sk: .139 }],
    0x59: [.683, .143, .668, { ic: .046, sk: .0833 }],
    0x5A: [.683, 0, .725, { ic: .042, sk: .139 }],
};
