"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.largeop = void 0;
exports.largeop = {
    0x28: [1.15, .649, .597],
    0x29: [1.15, .649, .597],
    0x2F: [1.15, .649, .811],
    0x5B: [1.15, .649, .472],
    0x5C: [1.15, .649, .811],
    0x5D: [1.15, .649, .472],
    0x7B: [1.15, .649, .667],
    0x7D: [1.15, .649, .667],
    0x2C6: [.772, -0.565, 1],
    0x2DC: [.75, -0.611, 1],
    0x302: [.772, -0.565, 0],
    0x303: [.75, -0.611, 0],
    0x2016: [.602, 0, .778],
    0x2044: [1.15, .649, .811],
    0x2191: [.6, 0, .667],
    0x2193: [.6, 0, .667],
    0x21D1: [.599, 0, .778],
    0x21D3: [.6, 0, .778],
    0x220F: [.95, .45, 1.278],
    0x2210: [.95, .45, 1.278],
    0x2211: [.95, .45, 1.444],
    0x221A: [1.15, .65, 1, { ic: .02 }],
    0x2223: [.627, .015, .333],
    0x2225: [.627, .015, .556],
    0x222B: [1.36, .862, .556, { ic: .388 }],
    0x222C: [1.36, .862, 1.084, { ic: .388 }],
    0x222D: [1.36, .862, 1.592, { ic: .388 }],
    0x222E: [1.36, .862, .556, { ic: .388 }],
    0x22C0: [.95, .45, 1.111],
    0x22C1: [.95, .45, 1.111],
    0x22C2: [.949, .45, 1.111],
    0x22C3: [.95, .449, 1.111],
    0x2308: [1.15, .649, .528],
    0x2309: [1.15, .649, .528],
    0x230A: [1.15, .649, .528],
    0x230B: [1.15, .649, .528],
    0x2329: [1.15, .649, .611],
    0x232A: [1.15, .649, .611],
    0x23D0: [.602, 0, .667],
    0x2758: [.627, .015, .333],
    0x27E8: [1.15, .649, .611],
    0x27E9: [1.15, .649, .611],
    0x2A00: [.949, .449, 1.511],
    0x2A01: [.949, .449, 1.511],
    0x2A02: [.949, .449, 1.511],
    0x2A04: [.95, .449, 1.111],
    0x2A06: [.95, .45, 1.111],
    0x2A0C: [1.36, .862, 2.168, { ic: .388 }],
    0x3008: [1.15, .649, .611],
    0x3009: [1.15, .649, .611],
};
