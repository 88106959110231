"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.smallop = void 0;
exports.smallop = {
    0x28: [.85, .349, .458],
    0x29: [.85, .349, .458],
    0x2F: [.85, .349, .578],
    0x5B: [.85, .349, .417],
    0x5C: [.85, .349, .578],
    0x5D: [.85, .349, .417],
    0x7B: [.85, .349, .583],
    0x7D: [.85, .349, .583],
    0x2C6: [.744, -0.551, .556],
    0x2DC: [.722, -0.597, .556],
    0x302: [.744, -0.551, 0],
    0x303: [.722, -0.597, 0],
    0x2016: [.602, 0, .778],
    0x2044: [.85, .349, .578],
    0x2191: [.6, 0, .667],
    0x2193: [.6, 0, .667],
    0x21D1: [.599, 0, .778],
    0x21D3: [.6, 0, .778],
    0x220F: [.75, .25, .944],
    0x2210: [.75, .25, .944],
    0x2211: [.75, .25, 1.056],
    0x221A: [.85, .35, 1, { ic: .02 }],
    0x2223: [.627, .015, .333],
    0x2225: [.627, .015, .556],
    0x222B: [.805, .306, .472, { ic: .138 }],
    0x222C: [.805, .306, .819, { ic: .138 }],
    0x222D: [.805, .306, 1.166, { ic: .138 }],
    0x222E: [.805, .306, .472, { ic: .138 }],
    0x22C0: [.75, .249, .833],
    0x22C1: [.75, .249, .833],
    0x22C2: [.75, .249, .833],
    0x22C3: [.75, .249, .833],
    0x2308: [.85, .349, .472],
    0x2309: [.85, .349, .472],
    0x230A: [.85, .349, .472],
    0x230B: [.85, .349, .472],
    0x2329: [.85, .35, .472],
    0x232A: [.85, .35, .472],
    0x23D0: [.602, 0, .667],
    0x2758: [.627, .015, .333],
    0x27E8: [.85, .35, .472],
    0x27E9: [.85, .35, .472],
    0x2A00: [.75, .25, 1.111],
    0x2A01: [.75, .25, 1.111],
    0x2A02: [.75, .25, 1.111],
    0x2A04: [.75, .249, .833],
    0x2A06: [.75, .249, .833],
    0x2A0C: [.805, .306, 1.638, { ic: .138 }],
    0x3008: [.85, .35, .472],
    0x3009: [.85, .35, .472],
};
