"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sansSerifItalic = void 0;
exports.sansSerifItalic = {
    0x21: [.694, 0, .319, { ic: .036 }],
    0x22: [.694, -0.471, .5],
    0x23: [.694, .194, .833, { ic: .018 }],
    0x24: [.75, .056, .5, { ic: .065 }],
    0x25: [.75, .056, .833],
    0x26: [.716, .022, .758],
    0x27: [.694, -0.471, .278, { ic: .057 }],
    0x28: [.75, .25, .389, { ic: .102 }],
    0x29: [.75, .25, .389],
    0x2A: [.75, -0.306, .5, { ic: .068 }],
    0x2B: [.583, .083, .778],
    0x2C: [.098, .125, .278],
    0x2D: [.259, -0.186, .333],
    0x2E: [.098, 0, .278],
    0x2F: [.75, .25, .5, { ic: .1 }],
    0x30: [.678, .022, .5, { ic: .049 }],
    0x31: [.678, 0, .5],
    0x32: [.678, 0, .5, { ic: .051 }],
    0x33: [.678, .022, .5, { ic: .044 }],
    0x34: [.656, 0, .5, { ic: .021 }],
    0x35: [.656, .022, .5, { ic: .055 }],
    0x36: [.678, .022, .5, { ic: .048 }],
    0x37: [.656, .011, .5, { ic: .096 }],
    0x38: [.678, .022, .5, { ic: .054 }],
    0x39: [.677, .022, .5, { ic: .045 }],
    0x3A: [.444, 0, .278],
    0x3B: [.444, .125, .278],
    0x3D: [.37, -0.13, .778, { ic: .018 }],
    0x3F: [.704, 0, .472, { ic: .064 }],
    0x40: [.705, .01, .667, { ic: .04 }],
    0x5B: [.75, .25, .289, { ic: .136 }],
    0x5D: [.75, .25, .289, { ic: .064 }],
    0x5E: [.694, -0.527, .5, { ic: .033 }],
    0x5F: [-0.038, .114, .5, { ic: .065 }],
    0x7E: [.327, -0.193, .5, { ic: .06 }],
    0x131: [.444, 0, .239, { ic: .019 }],
    0x237: [.444, .204, .267, { ic: .019 }],
    0x300: [.694, -0.527, 0],
    0x301: [.694, -0.527, 0, { ic: .063 }],
    0x302: [.694, -0.527, 0, { ic: .033 }],
    0x303: [.677, -0.543, 0, { ic: .06 }],
    0x304: [.631, -0.552, 0, { ic: .064 }],
    0x306: [.694, -0.508, 0, { ic: .073 }],
    0x307: [.68, -0.576, 0],
    0x308: [.68, -0.582, 0, { ic: .04 }],
    0x30A: [.693, -0.527, 0],
    0x30B: [.694, -0.527, 0, { ic: .063 }],
    0x30C: [.654, -0.487, 0, { ic: .06 }],
    0x391: [.694, 0, .667],
    0x392: [.694, 0, .667, { ic: .029 }],
    0x393: [.691, 0, .542, { ic: .104 }],
    0x394: [.694, 0, .833],
    0x395: [.691, 0, .597, { ic: .091 }],
    0x396: [.694, 0, .611, { ic: .091 }],
    0x397: [.694, 0, .708, { ic: .06 }],
    0x398: [.715, .022, .778, { ic: .026 }],
    0x399: [.694, 0, .278, { ic: .06 }],
    0x39A: [.694, 0, .694, { ic: .091 }],
    0x39B: [.694, 0, .611],
    0x39C: [.694, 0, .875, { ic: .054 }],
    0x39D: [.694, 0, .708, { ic: .058 }],
    0x39E: [.688, 0, .667, { ic: .098 }],
    0x39F: [.716, .022, .736, { ic: .027 }],
    0x3A0: [.691, 0, .708, { ic: .06 }],
    0x3A1: [.694, 0, .639, { ic: .051 }],
    0x3A3: [.694, 0, .722, { ic: .091 }],
    0x3A4: [.688, 0, .681, { ic: .109 }],
    0x3A5: [.716, 0, .778, { ic: .065 }],
    0x3A6: [.694, 0, .722, { ic: .021 }],
    0x3A7: [.694, 0, .667, { ic: .091 }],
    0x3A8: [.694, 0, .778, { ic: .076 }],
    0x3A9: [.716, 0, .722, { ic: .047 }],
    0x2013: [.312, -0.236, .5, { ic: .065 }],
    0x2014: [.312, -0.236, 1, { ic: .065 }],
    0x2015: [.312, -0.236, 1, { ic: .065 }],
    0x2017: [-0.038, .114, .5, { ic: .065 }],
    0x2018: [.694, -0.471, .278, { ic: .058 }],
    0x2019: [.694, -0.471, .278, { ic: .057 }],
    0x201C: [.694, -0.471, .5, { ic: .114 }],
    0x201D: [.694, -0.471, .5],
    0x2044: [.75, .25, .5, { ic: .1 }],
    0x2206: [.694, 0, .833],
};
