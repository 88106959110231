"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.texSize4 = void 0;
exports.texSize4 = {
    0x28: [1.75, 1.249, .792],
    0x29: [1.75, 1.249, .792],
    0x2F: [1.75, 1.249, 1.278],
    0x5B: [1.75, 1.249, .583],
    0x5C: [1.75, 1.249, 1.278],
    0x5D: [1.75, 1.249, .583],
    0x7B: [1.75, 1.249, .806],
    0x7D: [1.75, 1.249, .806],
    0x2C6: [.845, -0.561, 1.889, { ic: .013 }],
    0x2DC: [.823, -0.583, 1.889],
    0x302: [.845, -0.561, 0, { ic: .013 }],
    0x303: [.823, -0.583, 0],
    0x2044: [1.75, 1.249, 1.278],
    0x221A: [1.75, 1.25, 1, { ic: .02 }],
    0x2308: [1.75, 1.249, .639],
    0x2309: [1.75, 1.249, .639],
    0x230A: [1.75, 1.249, .639],
    0x230B: [1.75, 1.249, .639],
    0x2329: [1.75, 1.248, .806],
    0x232A: [1.75, 1.248, .806],
    0x239B: [1.154, .655, .875],
    0x239C: [.61, .01, .875],
    0x239D: [1.165, .644, .875],
    0x239E: [1.154, .655, .875],
    0x239F: [.61, .01, .875],
    0x23A0: [1.165, .644, .875],
    0x23A1: [1.154, .645, .667],
    0x23A2: [.602, 0, .667],
    0x23A3: [1.155, .644, .667],
    0x23A4: [1.154, .645, .667],
    0x23A5: [.602, 0, .667],
    0x23A6: [1.155, .644, .667],
    0x23A7: [.899, .01, .889],
    0x23A8: [1.16, .66, .889],
    0x23A9: [.01, .899, .889],
    0x23AA: [.29, .015, .889],
    0x23AB: [.899, .01, .889],
    0x23AC: [1.16, .66, .889],
    0x23AD: [.01, .899, .889],
    0x23B7: [.935, .885, 1.056],
    0x27E8: [1.75, 1.248, .806],
    0x27E9: [1.75, 1.248, .806],
    0x3008: [1.75, 1.248, .806],
    0x3009: [1.75, 1.248, .806],
    0xE000: [.625, .014, 1.056],
    0xE001: [.605, .014, 1.056, { ic: .02 }],
    0xE150: [.12, .213, .45, { ic: .01 }],
    0xE151: [.12, .213, .45, { ic: .024 }],
    0xE152: [.333, 0, .45, { ic: .01 }],
    0xE153: [.333, 0, .45, { ic: .024 }],
    0xE154: [.32, .2, .4, { ic: .01 }],
    0xE155: [.333, 0, .9, { ic: .01 }],
    0xE156: [.12, .213, .9, { ic: .01 }],
};
